html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

a {
	text-decoration: none;
}

a:active, a:hover, a:focus {
	outline: 0;
}

ul, li {
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	font-weight: normal;
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: border-box;
}

:focus {
	outline: 0;
}

img, audio, video {
	max-width: 100%;
	height: auto;
}

audio, canvas, iframe, video, img, svg {
	vertical-align: middle;
}

iframe {
	border: 0
}

textarea {
	resize: none; /*remove the resize handle on the bottom right*/
	overflow: auto;
	vertical-align: top;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

input, textarea, select, button {
	outline: none;
	border: none;
	margin: 0;
}

button, input {
	line-height: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td, th {
	padding: 0;
	text-align: left;
}
